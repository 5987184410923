<template>
    <div class="alert download_cont" v-show="payShow">
        <div class="cont middle">
            <header class="container_top">{{alertTxt.tit}}</header>
            <vue-qr :text="url" :size="224" :margin="0" :dotScale = '1'></vue-qr>
            <span class="close_icon" @click="closePay"></span>
            <span class="container_bot">{{alertTxt.txt}}</span>
         </div>
    </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
    components:{
        vueQr
    },
    props:{
        payShow: {
            type:Boolean
        },
        url:{
            type:String
        },
        alertTxt:{
            type:Object
        }
    },
    watch: {
        payShow(newValue, oldValue) {
            this.payShow = newValue;
    　　},
        url(newValue, oldValue) {
            this.url = newValue;
    　　},
        alertTxt(newValue, oldValue) {
            this.alertTxt = newValue;
        }
    },
    data() {
        return {
        }
    },
    methods: {
        closePay() {
            this.$emit('closePay',false)
        }
    }
}
</script>

<style scoped>
@import '../../../components/downloadAlert/style/download.css';
</style>
