<template>
  <div class="pay_box">
    <!-- 加载动画 -->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>
    <Header></Header>
    <div class="pay_cont">
      <!-- 赛事信息 -->
      <div class="event_info min_cont">
        <span class="tit">赛事信息</span>
        <ul class="infos">
          <li class="info_item">赛事名称：&nbsp;{{ orderInfo.eventName }}</li>
          <li class="info_item">赛事组别：&nbsp;{{ eventInfo.name }}</li>
          <li class="info_item">
            比赛时间：&nbsp;{{ orderInfo.matchStartDate | dealTime }}
          </li>
          <li class="info_item">
            报名费用：&nbsp;￥{{ eventInfo.fee | dealcount }}
          </li>
        </ul>
      </div>
      <!-- 报名人信息-->
      <div class="apply_info min_cont">
        <span class="tit">报名人信息</span>
        <ul class="chooseds">
          <li class="list" v-for="(item, index) in selectList" :key="index">
            <div class="info_show">
              <span class="info_txt name"
                >姓名：{{ item.name | dealname }}</span
              >
              <span class="info_txt sex_info">性别：{{ item.sex }}</span>
              <span class="info_txt num_info"
                >手机号：{{ item.mobileNum }}</span
              >
              <span class="info_txt idnum">证件号：{{ item.cardId }}</span>
            </div>
          </li>
        </ul>
        <ul class="additions" v-show="additions.length">
          <li
            class="addition_item"
            v-for="(item, index) in additions"
            :key="index"
          >
            <el-checkbox
              v-model="checked"
              class="checkbox_reset"
              disabled
            ></el-checkbox>
            <span class="addition_info">{{ item.name }}</span>
            <span class="addition_info">{{ item.price | dealcount }}元/人</span>
            <span class="addition_info">×{{ item.num }}</span>
          </li>
        </ul>
        <div class="pay_main">
          <span class="tit">选择支付方式</span>
          <ul class="pay_types clearfix">
            <li
              :class="['type f_left', { active: checkePaopao }]"
              @click="Paytype(1)"
            >
              <span class="type_icon f_left"></span>
              <div class="type_info f_left">
                <span>跑跑钱包</span>
                <span class="info_bot">推荐跑跑用户使用</span>
              </div>
            </li>
            <li
              :class="['type f_left', { active: checkeZfb }]"
              @click="Paytype(2)"
            >
              <span class="type_icon zfb f_left"></span>
              <div class="type_info f_left">
                <span>支付宝</span>
              </div>
            </li>
            <li
              :class="['type f_left', { active: checkeWx }]"
              @click="Paytype(3)"
            >
              <span class="type_icon wx f_left"></span>
              <div class="type_info f_left">
                <span>微信</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 支付 -->
      <div class="pay_main_bot clearfix min_cont">
        <span class="f_left player">共{{ selectList.length }}位参赛者</span>
        <div class="pay_main_bot_right f_right">
          <span class="tprice f_left"
            >合计：￥<span>{{ orderInfo.totalFee | dealcount }}</span></span
          >
          <button class="paySure apply f_right" @click="payment">
            立即支付
          </button>
        </div>
      </div>
    </div>
    <Footer :footerBgColor="'#fff'"></Footer>
    <!-- 弹框下载app -->
    <Download
      :isDownloadshow="isDownloadshow"
      :url="downloadUrl"
      @closeAlert="closeAlert"
    ></Download>
    <!-- 弹框支付 -->
    <payPicker
      :payShow="payShow"
      :url="payUrl"
      :alertTxt="alertTxt"
      @closePay="closePay"
    ></payPicker>
  </div>
</template>
<script>
import { Order, Pay } from "../../config/request";
import { getDate } from "../../config/utils";
import linkedmeLink from "../../config/linkdeme";
import vueQr from "vue-qr";
import payPicker from "./payDialog/payDialog";
export default {
  name: "pay",
  components: {
    vueQr,
    payPicker,
  },
  filters: {
    dealTime(v) {
      return getDate(v).substring(0, 11);
    },
    //价钱显示两位小数
    dealcount(v) {
      return Number(v).toFixed(2);
    },
    // 姓名显示最多6个
    dealname(v) {
      return v.length > 6 ? v.substr(0, 6) + "..." : v;
    },
  },
  data() {
    return {
      userInfo: {}, //用户信息
      isDownloadshow: false, //是否弹出下载app弹框
      downloadUrl: "", //下载app弹框二维码url
      entryId: "", //赛事类别id
      outTradeNo: "", //订单编号
      orderNew: "", //新订单
      orderId: "", //订单id
      eventId: "", //赛事id
      mobile: "", //用户手机号
      orderInfo: "", //订单总信息
      selectList: [], //报名人列表
      eventInfo: {}, //赛事信息
      additions: [], //附加项列表
      checked: true, //选中 不可取消
      checkePaopao: false, //选择跑跑支付
      checkeZfb: false, //选择支付宝支付
      checkeWx: false, //选择微信支付
      payShow: false, //支付弹框
      payUrl: "", //支付二维码url
      alertTxt: {
        tit: "",
        txt: "",
      }, //支付弹框文本
      timer: null,
      sub: "",
      isLoading: true,
    };
  },
  methods: {
    // 选择支付方式
    Paytype(type) {
      if (type == 1) {
        this.checkePaopao = true;
        this.checkeZfb = false;
        this.checkeWx = false;
      } else if (type == 2) {
        this.checkePaopao = false;
        this.checkeZfb = true;
        this.checkeWx = false;
      } else if (type == 3) {
        this.checkePaopao = false;
        this.checkeZfb = false;
        this.checkeWx = true;
      }
    },
    // 获取订单
    getEntroll() {
      let outTradeNo = this.outTradeNo;
      Order.getEnroll({
        outTradeNo: outTradeNo,
        mobile: this.mobile,
      }).then((res) => {
        this.isLoading = false;
        if (res.code == 1) {
          if (res.objectData.list.length && res.objectData.entry) {
            // 订单总信息
            this.orderInfo = res.objectData;
            //订单id
            this.orderId = res.objectData.list[0].eventPayId;
            // 报名人列表
            this.selectList = res.objectData.list;
            // 赛事信息
            this.eventInfo = res.objectData.entry;
            // 附加项列表
            this.additions = res.objectData.additions;
          } else {
            this.$router.push({
              path: "/",
            });
          }
        }
      });
    },
    //立即支付
    payment() {
      let total_fee = parseInt(
        Number(this.orderInfo.totalFee).toFixed(2) * 100
      );
      let fee = Number(this.orderInfo.totalFee).toFixed(2);
      let body = this.orderInfo.eventName + "-" + this.eventInfo.name;
      let accountId = this.userInfo.id;
      let outTradeNo = this.outTradeNo;
      let mobile = this.mobile;
      let payFrom = 1;
      // payType 0 跑跑钱包   1 微信支付  2 支付宝支付  3 微信公众号支付  4 手机网页支付宝支付  5 邀请码支付  6 微信扫码支付
      // payFrom   1 马拉松报名 2 马拉松小程序 3 跑跑APP 4 陪跑小程序
      if (this.checkePaopao) {
        // 弹下载框 跳到对应赛事详情页
        this.isDownloadshow = true;
        linkedmeLink({
          path: "run/malasong", //跳转app的名字
          id: this.eventId,
        }).then((res) => {
          this.downloadUrl = res.url;
        });
      } else if (this.checkeZfb) {
        // 支付宝支付
        let payType = 7;
        Pay.ZfbPay({
          body: body,
          total_fee: total_fee,
          accountId: accountId,
          mobile: mobile,
          payType: payType,
          payFrom: 1,
          outTradeNo,
        }).then((res) => {
          if (res.data.payUrl) {
            let payInfo = this.escape2Html(res.data.payUrl);
            // var newwindow = window.open("#","_blank");
            // newwindow.document.write(payInfo);
            const div = document.createElement("div");
            div.innerHTML = payInfo;
            document.body.appendChild(div);
            document.forms[0].submit();
          }
        });
        // let payType = 2;
        // Pay.aliPay({
        //     body:body,
        //     fee:fee,
        //     outTradeNo,
        //     marathonName:'马拉松报名',
        // }).then(res => {
        //     // console.log(res)
        //     // window.open(res.data.payParams, '_blank')
        //     window.location.href=res.url
        // })
      } else if (this.checkeWx) {
        // 微信支付
        let payType = 6;
        Pay.ZfbPay({
          total_fee: total_fee,
          payType: payType,
          body: body,
          id: accountId,
          mobile,
          payFrom,
          outTradeNo: outTradeNo,
          // paopaoPay:'paopaoPay',
          // marathonName:''
        }).then((res) => {
          if (res.code == 1) {
            this.payUrl = res.data.codeUrl;
            this.orderNew = res.objectData;
            this.payShow = true;
            (this.alertTxt = {
              tit: "微信支付",
              txt: "请打开手机微信扫码付款",
            }),
              //轮询获取订单状态
              this.selectOrder();
          }
        });
      } else {
        this.$alert("请您选择支付方式!", "消息提示", {
          dangerouslyUseHTMLString: true,
        });
      }
    },
    // 轮询查询订单状态
    selectOrder() {
      let payId = this.orderId;
      this.timer = window.setInterval(() => {
        Pay.select({
          payId,
        }).then((res) => {
          if (res.code == 1 || res.code == 501) {
            window.clearInterval(this.timer);
            if (res.code == 1) {
              this.closePay();
            }
          }
        });
      }, 1000);
    },
    // 关闭下载app弹框
    closeAlert() {
      this.isDownloadshow = false;
    },
    // 关闭支付弹框
    closePay() {
      this.payShow = false;
      if (this.timer) {
        window.clearInterval(this.timer);
      }
      this.$router.push({
        path: "/personalCenter",
        query: {
          componentName: "myEnrollRecord",
        },
      });
    },
    // 转义字符
    escape2Html(str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, i) {
        return arrEntities[i];
      });
    },
  },
  mounted() {
    // 获取地址栏id
    if (this.$route.query) {
      this.entryId = this.$route.query.entryId;
      this.outTradeNo = this.$route.query.outTradeNo;
      this.mobile = this.$route.query.mobile;
      this.getEntroll();
    }
    // 获取用户信息
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    } else {
      this.$router.push({
        path: "/",
      });
    }
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
};
</script>

<style>
@import "./style/pay.css";
</style>
<style lang="less" scoped>
.pay_types {
  li {
    display: flex;
    align-items: center;
    width: 170px;
    div {
      flex-grow: 1;
      text-align: center;
    }
  }
}
</style>
